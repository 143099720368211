import { title } from '@/utilities/filters'
import { prefixUrl } from '@/utilities/urls'
import api from '@/utilities/api'

export const DocumentNameMap = Object.freeze({
  plyForHireTermsAndConditions: 'allocationTermsAndConditions',
  locationTermsAndConditions: 'locationTermsAndConditions',
  whiteTicketTermsAndConditions: 'whiteTicketTermsAndConditions',
  driverCompanionAppTerms: 'driverCompanionAppTermsAndConditions',
  igoMarketplaceTermsAndConditions: 'iGoMarketPlaceTermsAndConditions',
  permitRatesTermsAndConditions: 'permitRatesTermsAndConditions',
})

export default class DocumentModel {
  constructor({ name, url }: Record<string, string> = {}) {
    this.name = name || ''
    this.url = url || ''
  }

  // properties // ***
  pdfLink: null | HTMLAnchorElement = null
  name = ''
  url = ''

  // getters // ***
  get label() {
    return title(this.name)
  }
  get pdfSrc(): null | string {
    const link = this.pdfLink
    if (!link) return null
    else return link.href + '#toolbar=0&navpanes=0&scrollbar=0'
  }
  get pdfUrl(): null | string {
    const url = this.url
    if (!this.url) return null
    else return prefixUrl(url, '/permit/opendocument?pathToDoc=')
  }

  // methods // ***
  async fetchPdf() {
    if (!this.pdfUrl) return
    const res = await api(this.pdfUrl, { method: 'GET' }, { responseType: 'blob' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([res?.data], { type: 'application/pdf' }))
    link.target = '_blank'
    this.pdfLink = link
  }
  async fromApi(doc: string[]) {
    const [serverName, url]: string[] = doc
    this.name = this.getClientNameFromServerName(serverName)
    this.url = url
    await this.fetchPdf()
    return this
  }
  getClientNameFromServerName(serverName: string): string {
    return Object.entries(DocumentNameMap).find(([, v]) => v === serverName)?.[0] || ''
  }
}
