
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import StepSummaryCard from '@/pages/permits/forms/applications/steps/StepSummaryCard.vue'
import InfoBoxes from '@/pages/permits/forms/InfoBoxes'
import { addId } from '@/utilities/functions'
import { getFormattedDate } from '@/utilities/org'
import { infoBoxes } from '@/pages/permits/forms/helpers'

@Component({ components: { 'c-info-boxes': InfoBoxes, 'c-step-summary-card': StepSummaryCard } })
export default class ReviewAndSubmitStep extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop({ required: true, type: Object })
  location!: Record<string, any>
  @Prop({ type: Boolean })
  motCertificateRequired!: boolean
  @Prop({ required: true, type: Object })
  values!: Record<string, any>

  // data // ***
  previewImage: Record<string, any> = { file: null, src: '' }
  reader: FileReader = new FileReader()

  // computed // ***
  get driverInformation(): Record<string, any>[] {
    const v: Record<string, any> = this.values[1] || {}
    return [
      { title: 'First Name', text: v.firstName || '-' },
      { title: 'Last Name', text: v.lastName || '-' },
      { title: 'Additional Driver?', text: v.additionalDriver ? 'Yes' : 'No' },
      { title: 'Address', text: this.getAddress(v) || '-' },
      { title: 'Mobile Phone Number', text: v.mobilePhoneNumber || '-' },
      { title: 'Email', text: v.personalEmailAddress || '-' },
      { title: "DVLA Driver's License Number", text: v.dvlaDriversLicenceNumber || '-' },
    ]
  }
  get infoBoxes() {
    return infoBoxes(this)
  }
  get stepCards() {
    const { driverInformation, supportingDocuments, vehicleInformation } = this
    return addId([
      { step: 1, info: addId(driverInformation), title: 'Driver Information' },
      { step: 2, info: addId(vehicleInformation), title: 'Vehicle Information' },
      { step: 3, info: addId(supportingDocuments), title: 'Supporting Documents' },
    ])
  }
  get supportingDocuments(): Record<string, any>[] {
    const v: Record<string, any> = this.values[3] || {}
    const sd = [
      { title: 'Recent Colour Photo', text: v.recentColourPhoto?.name || '-' },
      {
        title: 'Hackney Carriage Vehicle License',
        text: v.hackneyCarriageVehicleLicence?.name || '-',
      },
      {
        title: 'HCVL ExpiryDate',
        text: getFormattedDate(v.hackneyCarriageVehicleLicenceExpiryDate) || '-',
      },
      {
        title: 'Hackney Carriage Driver Licence',
        text: v.hackneyCarriageDriverLicence?.name || '-',
      },
      {
        title: 'HCDL ExpiryDate',
        text: getFormattedDate(v.hackneyCarriageDriverLicenceExpiryDate) || '-',
      },
      {
        title: 'Hire & Reward Vehicle Insurance Certificate',
        text: v.hireAndRewardVehicleInsuranceCertificate?.name || '-',
      },
      {
        title: 'HRVIC ExpiryDate',
        text: getFormattedDate(v.hireAndRewardVehicleInsuranceCertificateExpiryDate) || '-',
      },
    ]
    if (this.motCertificateRequired) {
      sd.push({ title: 'MOT Certificate', text: v.motCertificate?.name || '-' })
      sd.push({
        title: 'MOT Certificate',
        text: getFormattedDate(v.motCertificateExpiryDate) || '-',
      })
    }
    return sd
  }
  get termsAndConditions(): Record<string, any>[] {
    const v: Record<string, any> = this.values[4] || {}
    return [
      {
        title: 'Ply For Hire Terms And Conditions',
        text: v.plyForHireTermsAndConditions ? 'Accepted' : 'Not Accepted',
      },
      {
        title: 'White Ticket Terms And Conditions',
        text: v.whiteTicketTermsAndConditions ? 'Accepted' : 'Not Accepted',
      },
      {
        title: 'Driver Companion App Terms',
        text: v.driverCompanionAppTerms ? 'Accepted' : 'Not Accepted',
      },
      {
        title: 'Igo Marketplace Terms And Conditions',
        text: v.igoMarketplaceTermsAndConditions ? 'Accepted' : 'Not Accepted',
      },
      {
        title: 'Permit Rates Terms And Conditions',
        text: v.permitRatesTermsAndConditions ? 'Accepted' : 'Not Accepted',
      },
    ]
  }
  get vehicleInformation(): Record<string, any>[] {
    const v: Record<string, any> = this.values[2] || {}
    const dofr = getFormattedDate(v.dateOfFirstRegistration)
    return [
      { title: 'Registration', text: v.registration || '-' },
      // { title: 'Capability', text: v.capability || '-' },
      { title: 'Make', text: v.make || '-' },
      { title: 'Model', text: v.model || '-' },
      { title: 'Colour', text: v.colour || '-' },
      { title: 'Date of First Registration', text: dofr || '-' },
      { title: 'Available Seats', text: v.availableSeats || '-' },
      { title: 'HCDL Number', text: v.hcdlNumber || '-' },
      { title: 'Plate Number', text: v.plateNumber || '-' },
      { title: 'Is Wheelchair Accessible?', text: v.isWav ? 'Yes' : 'No' },
    ]
  }

  // created // ***
  created() {
    this.setPreviewImage()
  }

  // watch // ***
  @Watch('values.4.recentColourPhoto')
  onImageFileChanged() {
    this.setPreviewImage()
  }

  // methods // ***
  getAddress(a: Record<string, any> = {}): string {
    const { address1, address2, town, region, country, postCode } = a?.address || {}
    const fields = [address1, address2, town, region, country, postCode]
    const lines: string[] = []
    fields.forEach((f: string) => {
      const line = f ? f.trim() : ''
      if (!line) return
      else lines.push(line)
    })
    const address = lines.join(',\n').trim()
    if (!address) return '-'
    else return address
  }
  onBack() {
    this.$emit('back')
  }
  onCancel() {
    this.$emit('cancel')
  }
  onContinue() {
    console.log('onContinue method clicked - attempting emit')
    this.$emit('continue')
  }
  onEdit(e: Record<string, any>) {
    this.$emit('step:input', e.step)
  }
  setPreviewImage() {
    this.previewImage.file = this.values[4]?.recentColourPhoto
    if (!this.previewImage.file) return
    this.reader = new FileReader()
    this.reader.addEventListener('load', () => (this.previewImage.src = this.reader.result), false)
    this.reader.readAsDataURL(this.previewImage.file)
  }
}
