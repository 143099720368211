
import { Component, Prop, Vue } from 'vue-property-decorator'
import { supportingDocuments } from '@/pages/permits/forms/fields'
import { DateTime } from 'luxon'
import { PERMIT_FORM_MODES } from '@/utilities/constants'

const defaultValues = () => ({
  recentColourPhoto: null,
  hackneyCarriageVehicleLicence: null,
  hackneyCarriageVehicleLicenceExpiryDate: DateTime.now(),
  hackneyCarriageDriverLicence: null,
  hackneyCarriageDriverLicenceExpiryDate: DateTime.now(),
  hireAndRewardVehicleInsuranceCertificate: null,
  hireAndRewardVehicleInsuranceCertificateExpiryDate: DateTime.now(),
  motCertificate: null,
  motCertificateExpiryDate: DateTime.now(),
})

@Component
export default class SupportingDocumentsStep extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  location!: Record<string, any>
  @Prop({ type: Boolean })
  motCertificateRequired!: boolean
  @Prop({ type: Boolean })
  showSkip!: boolean

  // data // ***
  continueAttempted = false
  previewImage: Record<string, any> = { file: null, src: '' }
  reader: FileReader = new FileReader()
  values: Record<string, any> = defaultValues()
  invalidFileSelectedMessage: string | null = null
  invalidVehicleLicenseFileMessage: string | null = null
  invalidDriverLicenseFileMessage: string | null = null
  invalidVehicleInsuranceFileMessage: string | null = null
  invalidMotFileMessage: string | null = null

  // computed // ***
  get fields(): Record<string, any> {
    return supportingDocuments(this)
  }
  get loading(): boolean {
    return false
  }
  get mode() {
    return PERMIT_FORM_MODES.APPLY
  }
  get accept() {
    return '.jpg,.png,.jpeg'
  }

  // methods // ***
  onBack() {
    this.$emit('back')
  }
  onCancel() {
    this.$emit('cancel')
  }
  async onContinue() {
    this.continueAttempted = true
    const valid = await this.validate()
    if (valid) this.$emit('continue', this.values)
  }
  onRecentColorPhotoInput() {
    const fileName = this.values.recentColourPhoto.name
    const fileExtension = fileName.split('.').pop().toLowerCase()

    const allowedExtensions = ['jpg', 'jpeg', 'png']
    if (!allowedExtensions.includes(fileExtension)) {
      this.invalidFileSelectedMessage =
        'Invalid file type. Please select a jpg, jpeg, or png image.'
    } else {
      this.invalidFileSelectedMessage = '' // Clear any previous errors
    }
  }
  onVehicleLicenseFileInput() {
    const fileName = this.values.hackneyCarriageVehicleLicence.name
    const fileExtension = fileName.split('.').pop().toLowerCase()
    if (fileExtension == 'heic') {
      this.invalidVehicleLicenseFileMessage = 'Invalid file type. Please select a different file.'
    } else {
      this.invalidVehicleLicenseFileMessage = '' // Clear any previous errors
    }
  }
  onDriverLicencseFileInput() {
    const fileName = this.values.hackneyCarriageDriverLicence.name
    const fileExtension = fileName.split('.').pop().toLowerCase()
    if (fileExtension == 'heic') {
      this.invalidDriverLicenseFileMessage = 'Invalid file type. Please select a different file.'
    } else {
      this.invalidDriverLicenseFileMessage = '' // Clear any previous errors
    }
  }
  onVehicleInsuranceFileInput() {
    const fileName = this.values.hireAndRewardVehicleInsuranceCertificate.name
    const fileExtension = fileName.split('.').pop().toLowerCase()
    if (fileExtension == 'heic') {
      this.invalidVehicleInsuranceFileMessage = 'Invalid file type. Please select a different file.'
    } else {
      this.invalidVehicleInsuranceFileMessage = '' // Clear any previous errors
    }
  }
  onMotFileInput() {
    const fileName = this.values.motCertificate.name
    const fileExtension = fileName.split('.').pop().toLowerCase()
    if (fileExtension == 'heic') {
      this.invalidMotFileMessage = 'Invalid file type. Please select a different file.'
    } else {
      this.invalidMotFileMessage = '' // Clear any previous errors
    }
  }
  onSaveRecentColorPhotoInput() {
    if (!this.previewImage.file) return
    this.reader = new FileReader()
    this.reader.addEventListener('load', () => (this.previewImage.src = this.reader.result), false)
    this.reader.readAsDataURL(this.previewImage.file)
  }

  async onSkip() {
    const valid = await this.validate()
    if (valid) this.$emit('skip', this.values)
  }
  async validate(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    const valid = await refs.form.validate()
    if (this.invalidFileSelectedMessage) {
      return false
    }
    if (this.invalidVehicleLicenseFileMessage) {
      return false
    }
    if (this.invalidDriverLicenseFileMessage) {
      return false
    }
    if (this.invalidVehicleInsuranceFileMessage) {
      return false
    }
    if (this.invalidMotFileMessage) {
      return false
    }
    return !!valid
  }
}
