
import { Component, Prop, Vue } from 'vue-property-decorator'
import DriverInformation from '@/pages/permits/forms/applications/steps/DriverInformation'
import PermitDetails from '@/pages/permits/forms/PermitDetails'
import ReviewAndSubmit from '@/pages/permits/forms/applications/steps/ReviewAndSubmit'
import SupportingDocuments from '@/pages/permits/forms/applications/steps/SupportingDocuments'
import TermsAndConditionsStep from '@/pages/permits/forms/applications/steps/TermsAndConditionsStep'
import VehicleInformation from '@/pages/permits/forms/applications/steps/VehicleInformation'
import { motCertificateRequired } from '@/pages/permits/forms/helpers'
import { PERMIT_FORM_MODES } from '@/utilities/constants'

@Component({
  components: {
    'c-driver-information': DriverInformation,
    'c-permit-details': PermitDetails,
    'c-review-and-submit': ReviewAndSubmit,
    'c-supporting-documents': SupportingDocuments,
    'c-terms-and-conditions-step': TermsAndConditionsStep,
    'c-vehicle-information': VehicleInformation,
  },
})
export default class PermitApplicationForm extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop({ required: true, type: Object })
  location!: Record<string, any>

  // data // ***
  inReview = false
  mode: number = PERMIT_FORM_MODES.APPLY
  step = 1
  values: Record<string, any> = {}
  isAdditionalDriver = false

  // computed // ***
  get motCertificateRequired() {
    return motCertificateRequired(this)
  }
  get steps() {
    const steps = [
      { component: 'c-driver-information', title: 'Driver Information' },
      { component: 'c-vehicle-information', title: 'Vehicle Information' },
      { component: 'c-supporting-documents', title: 'Supporting Documents' },
      { component: 'c-terms-and-conditions-step', title: 'T&Cs' },
      { component: 'c-review-and-submit', title: 'Review and Submit' },
    ]
    return steps.map((s: Record<string, any>, i: number) => ({ ...s, step: i + 1 }))
  }

  // created // ***
  created() {
    this.resetForm()
  }

  // methods // ***
  onBack() {
    this.step--
  }
  onCancel() {
    this.$emit('cancel')
  }
  additionalDriverChanged() {
    this.isAdditionalDriver = !this.isAdditionalDriver
  }

  async onContinue(item: Record<string, any>, e: Record<string, any>) {
    const isLastStep = item.step === this.steps.length
    if (isLastStep) this.submit()
    else {
      this.setValue(item, e)
      const nextStep = item.step + 1
      this.step = nextStep
      const inReview = nextStep === this.steps.length
      if (inReview) this.inReview = true
    }
  }
  onSkip(item: Record<string, any>, e: Record<string, any>) {
    this.setValue(item, e)
    this.step = this.steps.length
  }
  resetForm() {
    this.step = 1
    this.resetValues()
  }
  resetValues() {
    this.setInitialValues()
  }
  setInitialValues() {
    const values: Record<string, any> = {}
    this.steps.forEach((s: Record<string, any>) => {
      values[s.step] = {}
    })
    Vue.set(this, 'values', values)
  }
  setValue(item: Record<string, any>, e: Record<string, any> = {}) {
    const lastStep = this.steps.length
    const { step } = item
    Vue.set(this.values, step, e)
    Vue.set(this.values[lastStep], step, e)
  }
  submit() {
    const reviewedValues = this.values[5]
    this.$emit('submit', reviewedValues)
  }
  getRefName(item: Record<string, any>) {
    return 'stepperContentRef' + item.step
  }
}
