
import i18n from '@/plugins/i18n'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { termsAndConditions } from '@/pages/permits/forms/fields'
import api from '@/utilities/api'
import DocumentModel, { DocumentNameMap } from '@/models/Document'

const defaultDialogs = () => ({})
const defaultDocs = () => ({})
const defaultValues = () => ({})

@Component
export default class TermsAndConditionsStep extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  location!: Record<string, any>
  @Prop({ type: Boolean })
  showSkip!: boolean

  // data // ***
  continueAttempted = false
  dialogs: Record<string, any> = defaultDialogs()
  docs: Record<string, any> = defaultDocs()
  values: Record<string, any> = defaultValues()

  // computed // ***
  get fields(): Record<string, any> {
    return termsAndConditions()
  }
  get loading(): boolean {
    return false
  }
  get urls() {
    const { permitAllocationLocationId: id } = this.location
    return { fetch: `/permit/permitAllocationLocation/${id}/documents` }
  }
  get viewableDocs() {
    return Object.values(this.docs)
  }
  get rules(): Record<string, any> {
    return {
      checkbox: [
        (v: boolean): boolean | string => v || String(i18n.t('validations.checkboxConfirm')),
      ],
    }
  }

  // created // ***
  created() {
    this.fetch()
  }

  // watch // ***
  @Watch('location')
  onLocationChanged() {
    this.fetch()
  }

  // methods // ***
  async fetch() {
    const { fetch: url } = this.urls
    const res = await api(url, undefined, undefined)
    this.setDocs(res?.data || {})
  }
  getTermsAndConditionsSrc(doc: Record<string, string>): string | null {
    return doc.pdfSrc || null
  }
  onBack() {
    this.$emit('back')
  }
  onCancel() {
    this.$emit('cancel')
  }
  async onContinue() {
    this.continueAttempted = true
    const valid = await this.validate()
    if (valid) this.$emit('continue', this.values)
  }
  onRead(doc: Record<string, string>) {
    this.dialogs[doc.name] = true
  }
  async onSkip() {
    const valid = await this.validate()
    if (valid) this.$emit('skip', this.values)
  }
  setDocs(data: Record<string, any>) {
    Object.entries(data)
      // filter out server side entries that are not recognised, e.g., permitLogo, etc.
      .filter(d => Object.entries(DocumentNameMap).some(([, v]) => d[0] && d[1] && v === d[0]))
      // create new document model instance for each document from server
      .map(d => Vue.observable(new DocumentModel()).fromApi(d))
      // make dialog and value entry for new document, as well as storing document in local state
      .forEach(async d => {
        const res = await d
        Vue.set(this.dialogs, res.name, false)
        Vue.set(this.docs, res.name, res)
        Vue.set(this.values, res.name, false)
      })
  }
  async validate(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    const valid = await refs.form.validate()
    return !!valid
  }
}
