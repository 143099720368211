
import { infoBoxes } from '@/pages/permits/forms/helpers'
import InfoBoxes from '@/pages/permits/forms/InfoBoxes'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: { 'c-info-boxes': InfoBoxes } })
export default class PermitDetails extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  location!: Record<string, any>
  @Prop({ required: false, type: Boolean })
  isAdditionalDriver!: boolean

  // computed // ***
  get infoBoxes() {
    return infoBoxes(this)
  }

  // methods // ***
  onCancel() {
    this.$emit('cancel')
  }
  onContinue() {
    this.$emit('continue')
  }
  async onSkip() {
    this.$emit('skip')
  }
}
